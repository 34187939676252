import type { BreakpointType } from '@gik/core/hooks/hooks/BreakpointHooks';
import bemBlock from '@gik/core/utils/bemBlock';
import { Container } from '@gik/ui/Container';
import React from 'react';

const blockName = 'nav-bar';

interface BaseNavBarProps {
  className?: string;
  containerClassName?: string;
  contained?: boolean;
  blurred?: boolean;
}

type UncontainedNavBarProps = React.PropsWithChildren<BaseNavBarProps & { contained?: false | undefined }>;
type ContainedNavBarProps = React.PropsWithChildren<BaseNavBarProps & { contained?: true } & BreakpointType>;

export type NavBarProps = UncontainedNavBarProps | ContainedNavBarProps;

function NavBarComp(args: UncontainedNavBarProps): React.ReactElement<UncontainedNavBarProps>;
function NavBarComp(args: ContainedNavBarProps): React.ReactElement<ContainedNavBarProps>;
function NavBarComp(props: NavBarProps): React.ReactElement<NavBarProps> {
  const { contained = false, blurred = false, children, className, containerClassName } = props;
  const { breakpoint } = props as ContainedNavBarProps;

  const bem = bemBlock(blockName);
  const containerClassNames = bem('container', null, containerClassName);

  return (
    <header className={bem(null, [{ blurred }], [className])}>
      {contained ? (
        <Container className={containerClassNames} breakpoint={breakpoint}>
          {children}
        </Container>
      ) : (
        <div className={containerClassNames}>{children}</div>
      )}
    </header>
  );
}

export const NavBar = React.memo(NavBarComp);
