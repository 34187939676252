import { useBemCN } from '@gik/core/utils/bemBlock';
import { copyLinkShareAnalytics } from '@gik/core/utils/ShareUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { CopyButton } from '@gik/ui/CopyButton';
import { HStack } from '@gik/ui/HStack/HStack';
import { Input } from '@gik/ui/Input';
import { Separator } from '@gik/ui/Separator';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareInkindButtons } from './ShareInkindButtons';

export interface ShareInkindPageSectionProps {
  pageShortUrl: string;

  emailInvitationsButton?: boolean;
  moreOptionsButton?: boolean;
  socialShareButtons?: boolean;
  buttonsPortal?: React.MutableRefObject<Element>;

  onEmailInvitation?(): void;
  onMoreOptions?(): void;
}

function ShareInkindPageSectionComp({
  pageShortUrl,
  emailInvitationsButton,
  moreOptionsButton,
  socialShareButtons = true,
  onEmailInvitation,
  onMoreOptions,
}: ShareInkindPageSectionProps): React.ReactElement {
  const { t } = useTranslation();

  const bem = useBemCN('share-inkind-modal');

  return (
    <div {...bem()}>
      <p {...bem('content-title')}>{t(translationKeys.ShareInkindModalContentTitle)}</p>
      <p {...bem('description')}>{t(translationKeys.ShareInkindModalDescription)}</p>

      <ShareInkindButtons
        {...bem('buttons')}
        pageShortUrl={pageShortUrl}
        moreOptionsButton={moreOptionsButton}
        emailInvitationsButton={emailInvitationsButton}
        socialShareButtons={socialShareButtons}
        onEmailInvitation={onEmailInvitation}
        onMoreOptions={onMoreOptions}
      />

      {/* {renderPortal(<div {...bem('footer-wrapper')}>test</div>, () => buttonsPortal.current)} */}

      <Separator />
      <div {...bem('footer-wrapper')}>
        <strong {...bem('copy-page-link')}>COPY PAGE LINK</strong>
        <HStack {...bem('footer-hstack')}>
          <Input {...bem('input')} value={pageShortUrl} readOnly selectOnFocus variant="default-solid" />
          <CopyButton
            {...bem('btn-copy')}
            variant="primary"
            textToCopy={pageShortUrl}
            title="copy"
            size="base"
            circle
            notificationText="Link copied"
            onClick={() => copyLinkShareAnalytics()}
          />
        </HStack>
      </div>
    </div>
  );
}

export const ShareInkindPageSection = withComponentErrorBoundary(ShareInkindPageSectionComp);
