import { useInkind } from '@gik/api/inkinds/inkind';
import routes from '@gik/core/routes';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import React from 'react';
import { InkindEmailInvitationsSection } from './EmailInvitationsModal';
import { ShareInkindPageSection } from './ShareInkindPageSection';

export interface ShareInkindModalProps {
  pageShortUrl: string;
  pageTitle: string;
  emailInvitationsButton?: boolean;
  moreOptionsButton?: boolean;
  socialShareButtons?: boolean;
  buttonsPortal?: React.MutableRefObject<Element>;
  inkindRouteId: string;
  isOrganizer?: boolean;
  pageNumber?: number;
  onSuccess?(): void;
}

function ShareInkindModalContentComp({
  pageShortUrl,
  pageTitle,
  pageNumber = 0,
  buttonsPortal,
  inkindRouteId,
  emailInvitationsButton,
  moreOptionsButton,
  onSuccess,
}: ShareInkindModalProps): React.ReactElement {
  const bem = useBemCN('share-inkind-modal-content');

  const [page, setPage] = React.useState<number>(pageNumber);

  const { data: inkindPage } = useInkind(inkindRouteId);

  const onMoreOptions = React.useCallback(() => {
    const url = `${routes.inkinds}/${inkindRouteId}/${inkindPage?.slug}`;

    navigator.share({
      url,
    });
  }, [inkindPage?.slug, inkindRouteId]);

  const handleEmailInvitation = React.useCallback(() => {
    setPage(1);
  }, []);

  const handleBack = React.useCallback(() => {
    setPage(0);
  }, []);

  return (
    <div {...bem(null, [{ [`page-${page}`]: true }])}>
      {page === 0 && (
        <ShareInkindPageSection
          onEmailInvitation={handleEmailInvitation}
          onMoreOptions={onMoreOptions}
          moreOptionsButton={moreOptionsButton}
          emailInvitationsButton={emailInvitationsButton}
          pageShortUrl={pageShortUrl}
        />
      )}
      {page === 1 && (
        <InkindEmailInvitationsSection
          inkindRouteId={inkindRouteId}
          buttonsPortal={buttonsPortal}
          onBack={handleBack}
          onSuccess={onSuccess}
        />
      )}
    </div>
  );
}

export const ShareInkindModalContent = withComponentErrorBoundary(ShareInkindModalContentComp);
