import bemBlock from '@gik/core/utils/bemBlock';
import type { ISimplifiedSocialButtonProps } from '@gik/ui/SocialIcon/SocialIcon';
import { SocialIcon } from '@gik/ui/SocialIcon/SocialIcon';
import { Messenger } from '@gik/ui/SvgIcon/BrandIcons/Messenger';
import React from 'react';

const blockName = 'messenger-icon';

export function MessengerIcon({ className, ...otherProps }: ISimplifiedSocialButtonProps) {
  const bem = bemBlock(blockName);

  return <SocialIcon className={bem(null, null, [className])} icon={Messenger} {...otherProps} />;
}
