import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import bemBlock from '@gik/core/utils/bemBlock';
import i18n from '@gik/i18n';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { ModalButtonFooter } from '@gik/ui/Modal';
import { UI } from '@gik/ui/UIManager';
import React from 'react';
import type { ShareInkindModalProps } from './ShareInkindModal';
import { ShareInkindModalContent } from './ShareInkindModal';

export function openShareInkindModal(
  props: ShareInkindModalProps,
  initiatedOn: 'inkindPage' | 'profilePage' | 'supportersPage',
  inkindPageUrl: string,
  isOrganizer: boolean
): Promise<boolean> {
  Analytics.fireEvent(AnalyticsEvents.OpenShareInkindModal, {
    initiatedOn,
    isOrganizer: isOrganizer.toString(),
    inkindPageUrl,
  });

  const bem = bemBlock('share-inkind-modal');

  return UI.dialog(
    ({ footerRef, close }) => (
      <ShareInkindModalContent {...props} buttonsPortal={footerRef} onSuccess={() => close(true)} />
    ),
    {
      title: i18n.t(translationKeys.ShareInkindModalTitle),
      closable: true,
      autowidth: true,
      className: bem(),
      shouldCloseOnOverlayClick: false,
      footer: <ModalButtonFooter smallButtonsOnDesktop={false} centeredButtons={false} />,
    }
  );
}
