import bemBlock from '@gik/core/utils/bemBlock';
import type { ISimplifiedSocialButtonProps } from '@gik/ui/SocialIcon/SocialIcon';
import { SocialIcon } from '@gik/ui/SocialIcon/SocialIcon';
import { Facebook } from '@gik/ui/SvgIcon/BrandIcons/Facebook';
import React from 'react';

const blockName = 'facebook-icon';

export function FacebookIcon({ className, ...otherProps }: ISimplifiedSocialButtonProps) {
  const bem = bemBlock(blockName);

  return <SocialIcon className={bem(null, null, [className])} icon={Facebook} {...otherProps} />;
}
