import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import { LayoutTypes } from '@gik/core/types/layouts';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { openNewBrowserTab } from '@gik/core/utils/browser';
import {
  createFacebookShareUrl,
  createMessengerShareUrl,
  createTwitterShareUrl,
  facebookShareAnalytics,
  messengerShareAnalytics,
  twitterShareAnalytics,
} from '@gik/core/utils/ShareUtils';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import { FacebookIcon } from '@gik/ui/SocialIcon/FacebookIcon';
import { MessengerIcon } from '@gik/ui/SocialIcon/MessengerIcon';
import { TwitterIcon } from '@gik/ui/SocialIcon/TwitterIcon';
import ShareAlt from '@gik/ui/SvgIcon/GikIcons/ShareAlt';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import MailIcon from '@heroicons/react/solid/MailIcon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShareOption } from './ShareInkindOption';

export interface ShareInkindButtonsProps {
  pageShortUrl: string;
  emailInvitationsButton?: boolean;
  moreOptionsButton?: boolean;
  socialShareButtons?: boolean;
  buttonsPortal?: React.MutableRefObject<Element>;

  onEmailInvitation?(): void;
  onMoreOptions?(): void;
}

function ShareInkindButtonsComp({
  pageShortUrl,
  emailInvitationsButton,
  moreOptionsButton,
  socialShareButtons = true,
  onEmailInvitation,
  onMoreOptions,
}: ShareInkindButtonsProps): React.ReactElement {
  const { t } = useTranslation();
  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);

  const bem = useBemCN('share-inkind-buttons');

  const pageShortUrlEncoded = encodeURIComponent(pageShortUrl);

  let enabledButtonsCount = 0;
  if (emailInvitationsButton) enabledButtonsCount++;
  if (socialShareButtons) enabledButtonsCount += 3;
  if (moreOptionsButton) enabledButtonsCount++;

  return (
    <>
      <div
        {...bem(null, [
          { 'native-share': moreOptionsButton },
          { [`layout-${enabledButtonsCount}`]: true },
          { [`layout-${enabledButtonsCount}-email`]: enabledButtonsCount === 4 && emailInvitationsButton },
          { [`layout-${enabledButtonsCount}-more`]: enabledButtonsCount === 4 && moreOptionsButton },
        ])}
      >
        {/* Email Invitations button */}
        {emailInvitationsButton && (
          <Button
            {...bem('btn-email-invitations')}
            type="button"
            uppercase={false}
            variant="primary"
            prepend={<SvgIcon Icon={MailIcon} />}
            layout={LayoutTypes.vertical}
            onClick={onEmailInvitation}
          >
            {t(translationKeys.emailInvitations)}
          </Button>
        )}

        {socialShareButtons && (
          <>
            <ShareOption
              title="Facebook"
              className="facebook"
              onClick={() => {
                openNewBrowserTab(createFacebookShareUrl(pageShortUrlEncoded));
                facebookShareAnalytics();
              }}
              icon={<FacebookIcon />}
            />
            <ShareOption
              title="Messenger"
              className="messenger"
              onClick={() => {
                openNewBrowserTab(createMessengerShareUrl(pageShortUrlEncoded, isSmDown));
                messengerShareAnalytics();
              }}
              icon={<MessengerIcon />}
            />
            <ShareOption
              title="Twitter"
              className="twitter"
              onClick={() => {
                openNewBrowserTab(createTwitterShareUrl(pageShortUrlEncoded));
                twitterShareAnalytics();
              }}
              icon={<TwitterIcon />}
            />
          </>
        )}

        {moreOptionsButton && (
          <Button
            {...bem('btn-more-options')}
            type="button"
            uppercase={false}
            variant="default-light"
            prepend={<ShareAlt />}
            layout={LayoutTypes.vertical}
            onClick={onMoreOptions}
          >
            {t(translationKeys.moreSharingOptions)}
          </Button>
        )}
      </div>
    </>
  );
}

export const ShareInkindButtons = withComponentErrorBoundary(ShareInkindButtonsComp);
