import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const Messenger = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg viewBox="0 0 41 40" width={size} height={size} fill="none" ref={svgRef} {...props}>
        <path
          d="M20.1765 3.33398C10.929 3.33398 3.36279 10.234 3.36279 18.7507C3.36279 23.5507 5.75034 27.834 9.4998 30.6673L9.60069 36.6673L15.4014 33.534L15.351 33.5173C16.881 33.934 18.4951 34.1673 20.1765 34.1673C29.4241 34.1673 36.9902 27.2673 36.9902 18.7507C36.9902 10.234 29.4241 3.33398 20.1765 3.33398ZM21.9083 24.0173L17.7217 19.634L9.2476 24.0173L18.2934 14.634L22.6313 18.7507L30.786 14.634L21.9083 24.0173Z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
