import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import { LayoutTypes } from '@gik/core/types/layouts';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Button } from '@gik/ui/Button';
import type { UIButtonVariant } from '@gik/ui/typesValues';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ShareOptionProps {
  mobileOnly?: boolean;
  url?: string;
  title?: string;
  variant?: UIButtonVariant;
  icon: React.ReactNode;
  customContent?: React.ReactNode;
  className?: string;
  onClick?(): void;
}

export function ShareOption({
  mobileOnly,
  customContent,
  url,
  icon,
  title,
  variant,
  className,
  onClick,
}: ShareOptionProps): React.ReactElement {
  const isSmDown = useBreakpoint(Breakpoint.SM_DOWN);
  const bem = useBemCN('share-inkind-option');
  const { t } = useTranslation();

  return (
    <Button
      {...bem('button', className)}
      type="button"
      uppercase={false}
      variant={variant}
      prepend={icon}
      href={url}
      externalLink
      onClick={onClick}
      layout={LayoutTypes.vertical}
    >
      {title}
    </Button>
  );
}
