import bemBlock from '@gik/core/utils/bemBlock';
import type { ExternalLinkProps } from '@gik/ui/ExternalLink';
import { ExternalLink } from '@gik/ui/ExternalLink';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import React from 'react';
import type { UISize } from '../types';

export interface ISocialIconProps {
  href?: string;
  icon: SvgrComponent | React.FC;
  size?: UISize;
  className?: string;
}

export type ISimplifiedSocialButtonProps = Pick<ISocialIconProps, 'href'> &
  Pick<ISocialIconProps, 'className'> &
  Pick<ExternalLinkProps, 'onClick'>;
const blockName = 'social-icon';

export function SocialIcon({ href, icon, className, size = '2xl', ...otherProps }: ISocialIconProps) {
  const bem = bemBlock(blockName);

  // TODO: migrate to use SvgIcon
  // TODO: use packages/@gik/core/assets/img/social/facebook.svg etc. (Make a icon set for social icons)
  const child = <SvgIcon Icon={icon} size={size} {...otherProps} />;

  return href ? (
    <ExternalLink className={bem(null, null, [className])} href={href}>
      {child}
    </ExternalLink>
  ) : (
    <div className={bem(null, null, [className])}>{child}</div>
  );
}
