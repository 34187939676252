import { Analytics } from '@gik/analytics';
import type InkindUserUpdate from '@gik/inkind-page/models/InkindUserUpdate';

export const getUserUpdateLink = (update: InkindUserUpdate) => {
  const currentUrl = window.location.href;
  const queryStart = currentUrl.indexOf('?');
  const pageUrlWithoutQuery = queryStart < 0 ? currentUrl : currentUrl.substring(0, queryStart);
  const pageUrlWithoutModalBackButtonHistory = pageUrlWithoutQuery?.split('#')[0];
  return `${pageUrlWithoutModalBackButtonHistory}?userUpdate=${update.id}`;
};

export const createFacebookShareUrl = (encodedUrl: string): string => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
};

export const createMessengerShareUrl = (encodedUrl: string, isSmDown: boolean): string => {
  if (isSmDown) {
    return `fb-messenger://share?link=${encodedUrl}`;
  }

  return `https://www.facebook.com/dialog/send?app_id=928334690612401&link=${encodedUrl}&redirect_uri=${encodedUrl}`;
};

export const createTwitterShareUrl = (encodedUrl: string): string => {
  return `https://twitter.com/intent/tweet?text=Give%20InKind&via=giveinkind&url=${encodedUrl}`;
};

export const createEmailShareUrl = (encodedUrl: string, encodedSubject: string): string => {
  return `mailto:?subject=${encodedSubject}&body=${encodedUrl}`;
};

export const createTextMessageShareUrl = (encodedBody: string): string => {
  return `sms:?&body=${encodedBody}`;
};

export const facebookShareAnalytics = () => {
  Analytics.fireShareEvent('Facebook');
};

export const messengerShareAnalytics = () => {
  Analytics.fireShareEvent('Messenger');
};

export const twitterShareAnalytics = () => {
  Analytics.fireShareEvent('Twitter');
};

export const emailShareAnalytics = () => {
  Analytics.fireShareEvent('Email');
};

export const textMessageShareAnalytics = () => {
  Analytics.fireShareEvent('Text Message');
};

export const copyLinkShareAnalytics = () => {
  Analytics.fireShareEvent('Copy Link');
};
