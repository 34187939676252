import bemBlock from '@gik/core/utils/bemBlock';
import type { ISimplifiedSocialButtonProps } from '@gik/ui/SocialIcon/SocialIcon';
import { SocialIcon } from '@gik/ui/SocialIcon/SocialIcon';
import { Twitter } from '@gik/ui/SvgIcon/BrandIcons/Twitter';
import React from 'react';

const blockName = 'twitter-icon';

export function TwitterIcon({ className, ...otherProps }: ISimplifiedSocialButtonProps) {
  const bem = bemBlock(blockName);

  return <SocialIcon className={bem(null, null, [className])} icon={Twitter} {...otherProps} />;
}
