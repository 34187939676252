import React from 'react';
import type { BrandIconProps } from '.';
import { BrandIconDefaultSize } from '.';

export const Twitter = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg viewBox="0 0 41 40" width={size} height={size} fill="none" ref={svgRef} {...props}>
        <path
          d="M7.28138 23.857C7.71761 23.9399 8.16747 23.9869 8.62848 23.9869C9.28405 23.9869 9.91484 23.8991 10.5159 23.7346C7.23925 23.0788 4.76565 20.1862 4.76565 16.7208C4.76565 16.6886 4.76565 16.6614 4.76689 16.6293C5.73601 17.1662 6.8402 17.4879 8.0138 17.5262C6.09044 16.2445 4.82638 14.0546 4.82638 11.5703C4.82638 10.2589 5.17957 9.03403 5.79673 7.97375C9.33239 12.3027 14.6129 15.1471 20.5689 15.4465C20.4462 14.9244 20.3805 14.3763 20.3805 13.8171C20.3805 9.86668 23.5927 6.66602 27.5522 6.66602C29.6119 6.66602 31.4733 7.53206 32.7795 8.92392C34.4116 8.60225 35.9471 8.00715 37.3301 7.18564C36.7972 8.85835 35.662 10.2576 34.1823 11.1435C35.6311 10.974 37.0141 10.5843 38.298 10.0189C37.3363 11.4528 36.1243 12.711 34.7214 13.7218C34.7338 14.0262 34.7425 14.3342 34.7425 14.646C34.7425 24.0971 27.5312 34.9993 14.349 34.9993C10.3027 34.9993 6.53534 33.8129 3.36279 31.7838C3.92295 31.8519 4.49425 31.8841 5.07423 31.8841C8.42895 31.8841 11.5197 30.7421 13.9735 28.822C10.8381 28.7626 8.18977 26.6977 7.28014 23.8484"
          fill="currentColor"
        />
      </svg>
    );
  }
);

export const TwitterWhite = React.forwardRef(
  ({ size = BrandIconDefaultSize, ...props }: BrandIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg viewBox="0 0 41 40" width={size} height={size} fill="none" ref={svgRef} {...props}>
        <path
          d="M7.28138 23.857C7.71761 23.9399 8.16747 23.9869 8.62848 23.9869C9.28405 23.9869 9.91484 23.8991 10.5159 23.7346C7.23925 23.0788 4.76565 20.1862 4.76565 16.7208C4.76565 16.6886 4.76565 16.6614 4.76689 16.6293C5.73601 17.1662 6.8402 17.4879 8.0138 17.5262C6.09044 16.2445 4.82638 14.0546 4.82638 11.5703C4.82638 10.2589 5.17957 9.03403 5.79673 7.97375C9.33239 12.3027 14.6129 15.1471 20.5689 15.4465C20.4462 14.9244 20.3805 14.3763 20.3805 13.8171C20.3805 9.86668 23.5927 6.66602 27.5522 6.66602C29.6119 6.66602 31.4733 7.53206 32.7795 8.92392C34.4116 8.60225 35.9471 8.00715 37.3301 7.18564C36.7972 8.85835 35.662 10.2576 34.1823 11.1435C35.6311 10.974 37.0141 10.5843 38.298 10.0189C37.3363 11.4528 36.1243 12.711 34.7214 13.7218C34.7338 14.0262 34.7425 14.3342 34.7425 14.646C34.7425 24.0971 27.5312 34.9993 14.349 34.9993C10.3027 34.9993 6.53534 33.8129 3.36279 31.7838C3.92295 31.8519 4.49425 31.8841 5.07423 31.8841C8.42895 31.8841 11.5197 30.7421 13.9735 28.822C10.8381 28.7626 8.18977 26.6977 7.28014 23.8484"
          fill="currentColor"
        />
      </svg>
    );
  }
);
