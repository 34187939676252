import type { SWRConfigInterface } from '@gik/core/api/BaseAPIConfig';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useApi } from '@gik/core/api/swr/useApi';
import type { ISupporter } from '@gik/core/models/gik/ISupporter';

export function getSupporterInvitations(inkindRouteId: string) {
  return dotnetApi.get(`inkinds/${inkindRouteId}/supporterInvitations`);
}

export type createSupporterInvitationsParams = {
  emails: string[];
  invitationMessage?: string; // leave message empty for a resend invitation call
};

export function createSupporterInvitations(
  inkindRouteId: string,
  params: createSupporterInvitationsParams,
  resend = false
) {
  return dotnetApi.post(`inkinds/${inkindRouteId}/supporterInvitations`, {
    json: params,
    searchParams: resend
      ? {
          resend,
        }
      : undefined,
  });
}

export type deleteSupporterInvitationsParams = {
  emails?: string[];
};

export function deleteSupporterInvitations(
  inkindRouteId: string,
  params: deleteSupporterInvitationsParams,
  removeAll = false
) {
  return dotnetApi.delete(`inkinds/${inkindRouteId}/supporterInvitations`, {
    json: params,
    searchParams: removeAll
      ? {
          all: true,
        }
      : undefined,
  });
}

export function useSupporterInvitations(inkindRouteId: string, config?: SWRConfigInterface) {
  // important: the path must be null if there is no inkindRouteId yet, this is to prevent any loading from happening
  return useApi<ISupporter[]>(inkindRouteId ? `inkinds/${inkindRouteId}/supporterInvitations` : null, null, config);
}
