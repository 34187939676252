import { createSupporterInvitations } from '@gik/api/crm/invitees';
import { useInkind } from '@gik/api/inkinds/inkind';
import { openPremiumPageUpgradeModal } from '@gik/checkout/utils/openPremiumPageUpgradeModal';
import { emailAddressesField } from '@gik/core/form';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import { unblurApp } from '@gik/core/utils/AppUtils';
import bemBlock, { useBemCN } from '@gik/core/utils/bemBlock';
import type { CSVParseResult } from '@gik/core/utils/CSV';
import { parseCSV } from '@gik/core/utils/CSV';
import FileUtils from '@gik/core/utils/FileUtils';
import { openIntercomWindow } from '@gik/core/utils/Intercom';
import { renderPortal } from '@gik/core/utils/RenderPortal';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { translationKeys } from '@gik/inkind-page/i18n/en';
import { Button } from '@gik/ui/Button';
import type { FormSchemaEntry } from '@gik/ui/Form';
import { Form, FormField } from '@gik/ui/Form';
import { HStack } from '@gik/ui/HStack/HStack';
import type { SelectOptionsType } from '@gik/ui/Select';
import { Separator } from '@gik/ui/Separator';
import { SvgIcon } from '@gik/ui/SvgIcon';
import { UI } from '@gik/ui/UIManager';
import { VStack } from '@gik/ui/VStack/VStack';
import MailIcon from '@heroicons/react/outline/MailIcon';
import ChevronLeftIcon from '@heroicons/react/solid/ChevronLeftIcon';
import CloudUploadIcon from '@heroicons/react/solid/CloudUploadIcon';
import type { FormApi } from 'final-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import vCard from 'vcf';

const shareInkindModalBlockName = 'inkind-email-invitations-modal';
const bem = bemBlock(shareInkindModalBlockName);

export type InkindEmailInvitationsFormValues = {
  emailAddresses?: SelectOptionsType[];
  invitationMessage?: string;
};

export const getInviteUsersSchema: ({
  className,
  isImpersonating,
}: {
  className?: string;
  isImpersonating?: boolean;
}) => FormSchemaEntry[] = ({ className, isImpersonating }) => [
  emailAddressesField(
    { props: { acceptValuesOnBlur: true, className, classNamePrefix: 'gik-creatable-select' } },
    isImpersonating
  ),
  {
    name: 'invitationMessage',
    type: 'textarea',
    label: 'Personal Message',
    maxLength: 255,
    optional: true,
    props: {
      maxLength: 255,
      maxLengthDisplay: true,
      limitInputToMaxLength: true,
      rows: 7,
    },
  },
];

export type InkindEmailInvitationsSectionProps = {
  buttonsPortal?: React.MutableRefObject<Element>;
  inkindRouteId: string;
  isOrganizer?: boolean;
  isImpersonating?: boolean;
  onBack?(): void;
  onSuccess?(): void;
};

function InkindEmailInvitationsSectionComp({
  buttonsPortal,
  inkindRouteId,
  onBack,
  onSuccess,
}: InkindEmailInvitationsSectionProps): React.ReactElement {
  const { t } = useTranslation();

  const bem = useBemCN('inkind-email-invitations-modal-content');

  const isImpersonating = useUserStore(state => state.isImpersonating);
  const schema = React.useMemo(
    () => getInviteUsersSchema({ className: 'email-invitations', isImpersonating }),
    [isImpersonating]
  );

  const { data: inkind } = useInkind(inkindRouteId);

  const formApiRef = React.useRef<FormApi>();

  function extractEmailsFromCSV(csv: CSVParseResult) {
    let emails: string[] = [];

    // find emails columns and their index
    const emailColumns = [];

    csv.fields.forEach((item, index: number) => {
      const isEmail = item.toLowerCase().indexOf('e-mail') > -1;
      if (!isEmail) return;
      emailColumns.push({
        value: item,
        index,
      });
    });

    let emailFieldIndex = 0;

    // detect Google vs outlook type csv by analyzing the email column names
    // look for a dash that comes after the word "E-mail"

    const isGoogleCSV = emailColumns[0].value.lastIndexOf('-') > 1;
    if (isGoogleCSV) emailFieldIndex = 1;

    const primaryEmailField = emailFieldIndex === 1 ? emailColumns[emailFieldIndex].value : 'E-mail Address';

    emails = csv.data.map(item => item[primaryEmailField]).filter(item => !!item);

    return emails;
  }

  const handleImportVCards = React.useCallback(async () => {
    const attachResult = await FileUtils.attachContacts();

    // fix vcard typing
    // eslint-disable-next-line
    let items: any;
    let csv: CSVParseResult;
    let emails: SelectOptionsType[];

    switch (attachResult.mimetype) {
      case 'text/csv':
        // Attempt to parse as Google CSV or Outlook CSV
        csv = parseCSV(attachResult.text);
        // FIXME:
        formApiRef?.current?.mutators?.setEmailAddresses(
          extractEmailsFromCSV(csv).map((v: string) => ({ label: v, value: v }))
        );
        break;
      case 'text/vcard':
        // Parse as vCard
        items = vCard.parse(attachResult.text);

        emails = items
          .map(card => {
            switch (card.version) {
              default:
                return card.data.email?._data || card.data.email?.[0]?._data;
            }
          })
          .filter(item => item !== undefined)
          .map((v: string) => ({ label: v, value: v }));

        // FIXME:
        formApiRef?.current?.mutators?.setEmailAddresses(emails);
        break;
    }
  }, []);

  const handleSubmit = React.useCallback(
    async (values: InkindEmailInvitationsFormValues) => {
      const response = await createSupporterInvitations(inkindRouteId, {
        invitationMessage: values.invitationMessage,
        emails: values.emailAddresses.map(item => item.value),
      });

      if (!response.ok) {
        const errorResponse = await response.json<{ message: string }>();
        const errorBody = JSON.parse(errorResponse.message);

        UI.dialog(
          ({ close }) => {
            return (
              <div {...bem('limit-section')}>
                <span {...bem('limit-title')}>Invitation Limit Reached</span>
                <p>
                  As a security measure we limit invitations to {errorBody.limit}. Contact Customer Service with your
                  full list, and we’ll be happy to add it for you, once we confirm you’re a kind human person.
                </p>
                <VStack>
                  <Button variant="primary" onClick={openIntercomWindow}>
                    Contact Customer Service
                  </Button>
                  <Button variant="default-plain" onClick={close}>
                    Done
                  </Button>
                </VStack>
              </div>
            );
          },
          {
            header: false,
            className: 'gik-invitation-limit-modal',
          }
        );

        return;
      }
      UI.notifySuccess('Email invitations sent out successfully');
      onSuccess();
    },
    [bem, inkindRouteId, onSuccess]
  );

  const canEditPage = useInkindCan('manage', inkind.routeId, inkind.groupId);

  const setEmailAddresses = (args, state, { setIn, changeValue }) => {
    const field = state.fields['emailAddresses'];
    field?.change(args?.[0]);
    state.formState.submitFailed = true;
  };

  const setMessage = (args, state, { setIn, changeValue }) => {
    const field = state.fields['invitationMessage'];
    field?.change(args?.[0]);
    state.formState.submitFailed = true;
  };

  return (
    <div {...bem()}>
      <HStack {...bem('title-wrapper')}>
        <SvgIcon Icon={MailIcon} size="2xl" {...bem('title-icon')} />
        <span {...bem('title')}>{t(translationKeys.EmailInvitationsContentTitle)}</span>
      </HStack>
      <p {...bem('description')}>{t(translationKeys.EmailInvitationsDescription)}</p>

      <Form
        vertical
        onSubmit={handleSubmit}
        schema={schema}
        variant="default-solid"
        // initialValues={initialValues}
        id="inviteGroupMembersForm"
        // mutators={{ setEmailAddresses, setMessage }}
        render={({ isValid, isSubmitting }) => {
          const buttons = (
            <div {...bem('buttons')}>
              <div {...bem('buttons-left')}>
                <Button circle variant="default-light" onClick={onBack}>
                  <SvgIcon Icon={ChevronLeftIcon} />
                </Button>
              </div>
              <div>
                {/* <Button {...bem('btn-preview')} variant="default-plain" loading={isSubmitting}>
                  Preview Email
                </Button> */}
                <Button
                  {...bem('btnSubmit')}
                  form="inviteGroupMembersForm"
                  type="submit"
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Invite
                </Button>
              </div>
            </div>
          );

          return (
            <>
              {/* <Input {...bem('input')} value={pageShortUrl} readOnly selectOnFocus variant="default-solid" /> */}
              <FormField name="emailAddresses" />
              <HStack {...bem('email-buttons')}>
                <Button
                  size="sm"
                  {...bem('btn-import')}
                  pill
                  variant="white"
                  prepend={<CloudUploadIcon />}
                  customIconSize
                  onClick={handleImportVCards}
                >
                  Import vCards
                </Button>

                <Button
                  variant="primary-link"
                  size="sm"
                  externalLink
                  href="https://app.intercom.com/a/apps/knyvswax/articles/articles/6424835/show"
                >
                  Learn about adding emails
                </Button>
              </HStack>
              <Separator />
              <FormField name="invitationMessage" />
              <p {...bem('footer')}>
                {canEditPage && (
                  <div>
                    <strong>Page Organizers</strong> can track and resend
                    <br />
                    invitations from the <strong>Supporter View</strong>
                  </div>
                )}
                {!canEditPage && (
                  <div>
                    <Button
                      variant="primary-link"
                      onClick={() =>
                        openPremiumPageUpgradeModal({
                          initiatedOn: 'supportersPage',
                          variant: 'viewSupporters',
                          inkindRouteId,
                          inkindSlug: inkind.slug,
                          onPurchase: unblurApp,
                        })
                      }
                    >
                      Upgrade to a Premium Page
                    </Button>{' '}
                    to track and resend invitations
                  </div>
                )}
              </p>

              {renderPortal(buttons, () => buttonsPortal?.current)}
            </>
          );
        }}
      />
    </div>
  );
}

export const InkindEmailInvitationsSection = withComponentErrorBoundary(InkindEmailInvitationsSectionComp);

/*export function openInkindEmailInvitationsModal(props: InkindEmailInvitationsSectionProps): void {
  UI.dialog(({ footerRef }) => <InkindEmailInvitationsSection buttonsPortal={footerRef} {...props} />, {
    title: i18n.t(translationKeys.ShareInkindModalTitle),
    closable: true,
    autowidth: true,
    className: bem(),
    footer: <ModalButtonFooter smallButtonsOnDesktop={false} centeredButtons={false} />,
  });
}*/
