import Papa from 'papaparse';

export type CSVError = {
  code: string;
  message: string;
  row: number;
  type: string;
};

export type CSVParseResult = {
  fields: string[];
  data: unknown[];
  errors: CSVError[];
};

export function parseCSV(text: string): CSVParseResult {
  const result = Papa.parse(text, {
    header: true,
  });

  return {
    fields: result.meta.fields,
    data: result.data,
    errors: result.errors,
  };
}
