import React from 'react';
import type { GikIconProps } from '.';
import { GikIconDefaultSize } from '.';

export default React.forwardRef(
  ({ size = GikIconDefaultSize, ...props }: GikIconProps, svgRef: React.Ref<SVGSVGElement>) => {
    return (
      <svg viewBox="0 0 32 32" fill="none" width={size} height={size} ref={svgRef} {...props}>
        <path
          d="M11.1999 13.5119H8.7999C8.16338 13.5119 7.55293 13.7741 7.10285 14.2409C6.65276 14.7076 6.3999 15.3407 6.3999 16.0008L6.3999 24.7119C6.3999 25.372 6.65276 26.005 7.10285 26.4718C7.55293 26.9386 8.16338 27.2008 8.7999 27.2008L23.1999 27.2008C23.8364 27.2008 24.4469 26.9386 24.897 26.4718C25.347 26.005 25.5999 25.372 25.5999 24.7119L25.5999 16.0008C25.5999 15.3407 25.347 14.7076 24.897 14.2409C24.4469 13.7741 23.8364 13.5119 23.1999 13.5119H20.7999M19.5999 8.53412L15.9999 4.80078M15.9999 4.80078L12.3999 8.53412M15.9999 4.80078L15.9999 20.9786"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
);
